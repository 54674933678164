<template>
    <div>
        <div class="mt-3" style="display: flex;padding-right: 20px;box-sizing: border-box;">
            <p class="font-bold text-lg" style="margin-left: 20px;flex: 1;">{{ project_name }}</p>
            <el-button type="primary" size="small" v-if="class_Id && class_Id != ''"
                @click="gotoClassManageView">Official Course</el-button>
        </div>
        <div class="content_container">
            <div class="eventContainer">
                <div class="mainView">
                    <el-table v-if="ongoingArr.length > 0" :data="ongoingArr" empty-text="No Data"
                        :highlight-current-row="false" :highlight-selection-row="false"
                        :header-cell-class-name="'custom-head-cell'" :cell-style="tableCellstyle"
                        :row-class-name="'custom-row-bg'">
                        <el-table-column label="Event Name" align="left">
                            <template slot-scope="scope">
                                {{ scope.row.project_name }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            :label="'Event Time (' + account_continent + ': UTC+' + timezones[account_continent] + ')'"
                            align="left">
                            <template slot-scope="scope">
                                <div
                                    style="padding-left: 10px;white-space:pre-line;text-align: left;box-sizing: border-box;">

                                    <template v-for="(item, index) in scope.row.exam_time_arr">
                                        <div>
                                            {{ item.name }} ({{ item.exam_type }}):
                                            <ul>
                                                <li>&#8226; Start Time: {{ item.exam_begin_time_str }}</li>
                                                <li>&#8226; End Time: {{ item.exam_end_time_str }}</li>
                                            </ul>

                                        </div>
                                    </template>
                                </div>

                            </template>
                        </el-table-column>


                        <el-table-column width="200" label="Operations" align="left">
                            <template slot-scope="scope">
                                <div style="">
                                    <!--讲座暂时不开启-->
                                    <!-- <el-button v-if="scope.row.can_go_lecture == true" class="btn-link"
                                                @click="toPackage(scope.row)" type="primary" size="small">Enter
                                                Lecture</el-button> -->
                                    <el-button v-if="scope.row.can_go_exam == true" class="btn-link"
                                        @click="toStudentEventList(scope.row)" type="primary" size="small">Enter
                                        Exam</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>

                    <el-table v-if="completedArr.length > 0" :data="completedArr" empty-text="No Data"
                        :highlight-current-row="false" :highlight-selection-row="false"
                        :header-cell-class-name="'custom-head-cell'" :cell-style="tableCellstyle"
                        :row-class-name="'custom-row-bg'">
                        <el-table-column label="Event Name" align="left">
                            <template slot-scope="scope">
                                {{ scope.row.project.project_product }}
                            </template>
                        </el-table-column>


                        <el-table-column label="Score" align="left">

                            <template slot-scope="scope">
                                {{ scope.row.score.total_score }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Award" align="left">
                            <template slot-scope="scope">
                                <div>{{ formatGradeInfo(scope.row.level_and_grade.grade) }}
                                </div>
                                <div v-if="scope.row.awards && scope.row.awards.length > 0">
                                    <div v-for="(award, index) in scope.row.awards" :key="index">
                                        {{ award.award_lable }}
                                    </div>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column width="360" label="Operations" align="left">
                            <template slot-scope="scope">
                                <div style="float: left;">

                                    <el-button class="btn-link" @click="gotoAwardView(scope.row)" type="primary"
                                        size="small">Certificate</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    getStudentApplicationsV1,
} from "../../../api/wao";
import {
    getProjectInfoByCode
} from '../../../api/eae'

import Cookie from "js-cookie";
import { getUserId, getAccountContinent } from "../../../utils/store";

export default {
    data() {
        return {
            class_Id: '',
            project_code: this.$route.query.project_code,
            project_name: this.$route.query.project_name,
            loginId: getUserId(),
            activeName: 'second',
            defevent: 0,

            pendingArr: [],
            ongoingArr: [],
            completedArr: [],
            account_continent: getAccountContinent(),
            timezones: {
                'Asia': 8,
                'Oceania': 8,
                'Europe': 1,
                'Africa': 1,
                'North America': -4,
                'South America': -4

            },

        };
    },
    created() {

    },
    mounted() {
        this.loading = true;
        this.$nextTick(() => {
            this.$loading({
                lock: true,
                text: 'Loading...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        });
        this.getAppByEvent()
        this.requestProjectInfo()

    },
    methods: {


        requestProjectInfo() {
            getProjectInfoByCode(this.project_code).then((res) => {
                let project = res.data
                if (project.project_classes && project.project_classes.length > 0) {
                    this.class_Id = project.project_classes[0]
                }
            })
        },

        gotoClassManageView() {
            this.$router.push({
                path: '/home/official_course',
                query: {
                    project_code: this.project_code,
                    class_id: this.class_Id
                }
            })
        },


        formatGradeInfo(grade) {
            if (grade >= 4) {
                return 'Excellence'
            }
            if (grade >= 3) {
                return 'Distinction'
            }
            if (grade >= 2) {
                return 'Great Distinction'
            }
            return 'Highest Distinction'
        },

        gotoAwardView(row) {
            this.$router.push({
                path: '/wao/award',
                query: {
                    project_code: row.application_project,
                }
            })
        },


        toStudentEventList(row) {

            Cookie.set('exam_event_name', row.project_name)

            this.$router.push({
                path: '/home/exam-center/wao',
                query: {
                    project_code: row.application_project
                }
            })
        },

        getAppByEvent() {

            getStudentApplicationsV1(this.loginId, this.project_code).then((res) => {

                if (res.data.code == 0) {

                    this.pendingArr = res.data.data.pending_apps
                    this.ongoingArr = res.data.data.ongoing_apps
                    this.completedArr = res.data.data.completed_apps

                } else {
                    this.$message.error(res.msg);
                }
            }).finally(() => {
                this.$loading().close();
            });
        },

        tableCellstyle() {
            return 'background:  #FAE3E4;border:1px solid white;text-align:center; font-family: Ubuntu-Bold;'
        },
    },
};
</script>
<style lang="scss" scoped>
.content_container {
    max-width: 1360px;
    font-size: 12px;
    padding: 20px 20px;
    box-sizing: border-box;
}

/deep/ .el-table .custom-row-bg {
    background: #FAE3E4;
}

/deep/ .el-table .custom-head-cell {
    background: var(--c-primary);
    color: white;
    text-align: center;
    border: 1px solid white;
    box-sizing: border-box;
    font-family: Ubuntu-Bold;
}
</style>